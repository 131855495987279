import React, { useState } from "react";
import { Layout, Avatar, Row, Col, Button, Select, Space, Dropdown, ConfigProvider, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { supportedLanguages, supportedDemoLanguages, languageOptions, storeLanguageAsCookie } from "../../../../../lang/index";

import AdminLinks from "../AdminLinks.jsx";
import Maintenance from "./Maintenance.jsx";

// https://flagpack.xyz/docs/flag-index/
const flagMapping = {
  nl: "NL",
  be: "BE",
  en: "GB-UKM",
  "en-GB": "GB-UKM",
  "en-US": "US",
  "nl-NL": "NL",
  "nl-BE": "BE",
  "fr-BE": "BE",
  "fr-FR": "FR",
  "it-IT": "IT",
  "de-DE": "DE",
  "es-ES": "ES"
};

const { Option } = Select;

const { Header: AntHeader } = Layout;

const updateAdminLocale = async (locale, adminId, csrfToken) => {
  try {
    const result = await fetch(`/admin/administrators/setLanguage`, {
      method: "POST",
      body: JSON.stringify({ locale }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    });

    storeLanguageAsCookie(locale);

    return result;
  } catch (e) {
    console.log("Something went wrong while changing the admin's language", e);
  }
  return {};
};

function LanguageDropDown({ selectedLanguage, onChangeHandler, betaMode }) {
  const languages = [...supportedLanguages, ...(betaMode ? supportedDemoLanguages : [])];
  return (
    languages.length > 1 && (
      <Select listHeight={384} style={{ width: betaMode ? "250px" : "200px" }} defaultValue={selectedLanguage} onChange={onChangeHandler}>
        {languages.map((lang) => (
          <Option key={lang} selected={selectedLanguage === lang} value={lang}>
            <div style={{ display: "flex", alignItems: "center" }}>{languageOptions[lang] || lang}</div>
          </Option>
        ))}
      </Select>
    )
  );
}

function AccountDropdown({csrfToken}) {
  const items = [
    {
      key: '1',
      label: (
        <Button type="text" href="/account" classNames="account-button">
          <FormattedMessage id="dashboard.layout.account" defaultMessage="Account" description="Layout: account button" />
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <form style={{ margin: 0 }} action="/logout" method="post" onSubmit={clearSessionStorage}>
          <input type="hidden" name="_csrf" value={csrfToken} />
          <Button type="text" htmlType="submit" classNames="account-button">
            <FormattedMessage id="dashboard.layout.logout" defaultMessage="Log out" description="Layout: Log out button" />
          </Button>
        </form>
      ),
    }
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            textHoverBg: "transparent",
          },
        },
      }}
    >
      <Dropdown menu={{items, placement: "bottom"}}>
        <Button><UserOutlined /></Button>
      </Dropdown>
    </ConfigProvider>
  );
}

const clearSessionStorage = () => {
  window.sessionStorage.clear();
};

function Header({ adminLinksViewModel, isAdminLoggedIn, adminId, csrfToken, adminLocale, show2FAWarning }) {
  const [error, setError] = useState(false);
  const { isDemoAdmin } = adminLinksViewModel;
  const handleLocaleChange = async (locale) => {
    const result = await updateAdminLocale(locale, adminId, csrfToken);
    if (result.status === 200) {
      window.location.reload();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <AntHeader style={{ position: "fixed", zIndex: 1000, width: "100%", backgroundColor: "#f8f8f8" }}>
        <Row wrap={false}>
          <Col flex="60px" style={{ alignSelf: "center" }}>
            <Avatar src="/images/Q_Logo_Color.svg" size={40} />
          </Col>
          <Col flex="auto">
            <AdminLinks adminLinksViewModel={adminLinksViewModel} />
          </Col>
          <Col style={{ alignSelf: "center" }}>
            <Space>
              {isAdminLoggedIn ? (
                <>
                  <LanguageDropDown selectedLanguage={adminLocale} onChangeHandler={handleLocaleChange} betaMode={isDemoAdmin} />
                  <AccountDropdown csrfToken={csrfToken}/>
                </>
              ) : (
                <Button href="/login">
                  <FormattedMessage id="dashboard.layout.login" defaultMessage="Log in" description="Layout: Log in button" />
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </AntHeader>
      <Maintenance locale={adminLocale} />
      { show2FAWarning && (
        <div style={{ margin: "1rem auto", width: "90%" }}>
          <Alert
            message={<FormattedMessage id="dashboard.layout.2faWarning" defaultMessage="Please <accountLink>enable Two-Factor Authentication</accountLink> to secure your account." description="Layout: 2FA warning" values={{ accountLink: (chunks) => (<a href="/account">{chunks}</a>)}} />}
            type="warning"
            showIcon
          />
        </div>
      )}
    </>
  );
}

export default Header;
